<template>
  <div class="bounded-list">
    <div
      ref="boundedList"
      class="height-limit"
      :style="displayedLinesStyle"
    >
      <slot></slot>
    </div>
    <div v-if="displayRedirectLink">
      <slot name="redirect-link"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bounded-list',
  props: {
    displayedLines: {
      type: [Number, String],
      default: 5
    },
    value: String,
    filterBar: {
      type: String,
      default: 'auto'
    },
    redirectLink: {
      type: String,
      default: 'true'
    },
    scrollBar: {
      type: String,
      default: 'auto'
    }
  },
  data: function () {
    return {
      lastDomChange: 0,
      rowHeight: 40,
      autoDisplay: false,
      overflow: 'auto',
      mutObs: undefined
    }
  },
  mounted () {
    if (this.scrollBar !== 'auto') {
      this.overflow = ['true', ''].includes(this.scrollBar) ? 'scroll' : 'hidden'
    }

    const mutOptions = {
      childList: true,
      subtree: true
    }

    this.mutObs = new MutationObserver(() => (this.lastDomChange = Date.now()))
    this.mutObs.observe(this.$refs.boundedList, mutOptions)
    this.lastDomChange = Date.now() // Force the lastDomChange watcher to trigger
  },
  dismounted () {
    this.mutObs.disconnect()
  },
  watch: {
    lastDomChange: function () {
      this.autoDisplay = this.$refs.boundedList.clientHeight < this.$refs.boundedList.scrollHeight
      // Ensure we got a leaf element to detect row height when working with v-list-group
      const el = [...this.$el.querySelectorAll('.height-limit .v-list-item').values()].slice().pop()
      this.rowHeight = el?.clientHeight || el?.minHeight // Get the client height or the min-height if the element is not rendered
    }
  },
  computed: {
    displayedLinesStyle () {
      return `max-height: ${Number(this.displayedLines) * this.rowHeight}px; overflow: ${this.overflow}`
    },
    displaySearchBar () {
      if (this.filtering) {
        return true
      }

      if (this.filterBar === 'auto') {
        return this.autoDisplay
      }
      return ['true', ''].includes(this.filterBar)
    },
    displayRedirectLink () {
      if (this.redirectLink === 'auto') {
        return this.autoDisplay
      }
      return ['true', ''].includes(this.redirectLink)
    },
    filtering: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="stylus" scoped >
.height-limit
  overflow-y auto
  scroll-snap-type y mandatory

>>>[role='button'], >>>[role='menuitem']
  scroll-snap-align start
</style>
